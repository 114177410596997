<template>
    <div class="card st-framework-card">

        <div class="card-header">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                        <font-awesome-icon :icon="icon" size="sm"/>
                    </td>
                    <td style="text-align: center;">
                        {{ $i18n.tc('translations.' + name, 2) }}
                    </td>
                    <td style="width: 50px;"/>
                </tr>
            </table>
        </div>

        <div class="card-body" v-if="pageLoaded">
            <div class="card-columns">
                <template v-for="(card, cardIndex) in cards">
                    <Table :key="'card-'+cardIndex+'-'+reloadIndex"
                           :header-style="card.header_style" :title="card.title"
                           :rows="card.rows" :fields="card.fields" :actions="card.actions" :state="state"
                           :th-actions-style="card.th_actions_style" :no-actions-label="card.no_actions_label"
                           :with-pagination="card.with_pagination" :lines-per-page="card.lines_per_page"
                           v-on:delete-notification="deleteNotification"
                           v-if="card.rows.length > 0"/>
                </template>
            </div>
        </div>

    </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    name: 'Dashboard',
    components: {
        Table,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            reloadIndex: 0,
            cards: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        deleteNotification(data) {
            this.state.loading = true;
            this.$http.delete(this.api + '/delete_notification/' + data.id).then((res) => {
                this.cards = res.data.cards;
                this.reloadIndex++;
                this.state.loading = false;
            }).catch((error) => {
                console.log("deleteNotification:fetchData():error:", error);
            });
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage', {}).then((res) => {
                this.cards = res.data.cards;
                this.reloadIndex++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("Dashboard:fetchData():error:", error);
            });
        },
    }
}
</script>

<style scoped>
</style>
